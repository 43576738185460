import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardQuizCard from "../Dashboard/Components/DashboardQuizCard";
import { useContext } from "react";
import appContext from "../../context/appContext";
import StickyFooterDashboard from "../Dashboard/Components/StickyFooterDashboard";
import ConfirmationDialogue from "../Teacher/confirmationdialogue/ConfirmationDialogue";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Info from "../../images/info.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import amplitude from "amplitude-js";
import classicon from "../../images/firstclass.png";
import { GetStartedCards } from "../Dashboard/Components/GetStartedCards";
import CheckCircle from "../../images/Tick.svg";
import JoinClassCodeDialogue from "../Teacher/create_classroom_dialogue/JoinClassCodeDialogue";
import DashboardSubjectCard from "../Dashboard/Components/DashboardSubjectCard";
import moment from "moment";
import UpgradePlan from "../Subscription/UpgradePlan";
import useGetQuizzes from "../../Hooks/Custom_hooks/Dashboard/getQuizzes";
import useGetQuizDataForCopy from "../../Hooks/Custom_hooks/Dashboard/getQuizDataForCopy";
import useGetStudentDataInQuiz from "../../Hooks/Custom_hooks/Dashboard/getStudentDataInQuiz";
import useRemoveQuiz from "../../Hooks/Custom_hooks/Dashboard/RemoveQuiz";
import useGetStudentSubscriptionTabData from "../../Hooks/Custom_hooks/Dashboard/getSubscriptionTabData";
import useCreateOrRetrieveCustomer from "../../Hooks/Custom_hooks/Dashboard/createOrRetrieveCustomer";
import useCreateCustomerPortalSession from "../../Hooks/Custom_hooks/Dashboard/createCustomerPortalSession";
import useGetSharedClassroom from "../../Hooks/Custom_hooks/Dashboard/getSharedClassroom";
import DashboardClassroomCard from "../Teacher/DashboardClassroomCard";
import DashboardSharedClassCard from "../Dashboard/Components/DashboardSharedClassCard";

function Dashboard() {
  const state = useContext(appContext);

  const navigate = useNavigate();
  const [quizzes, setQuizzes] = useState([]);
  const [foldersList, setFoldersList] = useState([]);
  const [classroomList, setClassroomList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(1);
  const [stickyEditIndex, setStickyEditIndex] = useState(-1);
  const [stickyEditQuiz, setStickyEditQuiz] = useState();
  const [toasterValue, setToasterValue] = useState();
  const [show, setShow] = useState(false);
  const [quizCount, setQuizCount] = useState(0);
  const [deleteQuizList, setDeleteQuizList] = useState([]);
  const [openDeleteConfirmationDialogue, setOpenDeleteConfirmationDialogue] = useState(false);
  const [isActiveQuiz, setIsActiveQuiz] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openQuizTooltip, setOpenQuizTooltip] = useState(false);
  const location = useLocation();
  const [classStatus, setClassStatus] = useState();
  const [retrievalStatus, setRetievalStatus] = useState();
  const [taskStatus, setTaskStatus] = useState(false);
  const [latestClassroom, setLatestClassroom] = useState();
  const [openJoinClassroomDialogue, setOpenJoinClassroomDialogue] = useState(false);
  const [expiredSubject, setExpiredSubject] = useState([]);
  const [mySubject, setMySubjects] = useState([]);
  const [subject, setSubject] = useState({});
  const [subjectName, setSubjectName] = useState("");
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjectData, setSubjectData] = useState(null);
  const [quizData, setQuizData] = useState();
  const [newData, setNewData] = useState();
  const [customer, setCustomer] = useState();
  const [portal, setPortal] = useState();
  const [subjectDataLoading, setSubjectDataLoading] = useState(true);
  const [quizDataLoading, setQuizDataLoading] = useState(true);
  const getQuizzes = useGetQuizzes();
  const getQuizDataForCopy = useGetQuizDataForCopy();
  const getStudentDataInQuiz = useGetStudentDataInQuiz();
  const removeQuiz = useRemoveQuiz();
  const getStudentSubscriptionTabData = useGetStudentSubscriptionTabData();
  const getCreateOrRetrieveCustomer = useCreateOrRetrieveCustomer();
  const getCreateCustomerPortalSession = useCreateCustomerPortalSession();
  const getSharedClassroom = useGetSharedClassroom();
  const handleOpenQuizTip = () => {
    setOpenQuizTooltip(true);
  };
  const handleCloseQuizTip = () => {
    setOpenQuizTooltip(false);
  };

  const handleOpen = (subject) => {
    setSubjectName(subject.subject_name);
    setSubject(subject);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickCloseJoinClassroomDialogue = () => {
    setOpenJoinClassroomDialogue(false);
  };
  const [folderDetail, setFolderDetail] = useState({
    folderName: "",
    description: "",
  });
  const [checked, setChecked] = useState([]);
  const [videoList, setVideoList] = useState([]);

  const refreshData = () => {
    getQuizzesList(null);
  };

  useEffect(() => {
    state.handleCloseTrialGetStartedPopup();
    state.setDashboardTabsState(1);
    localStorage.setItem("teacher_table_state", 1);
    getQuizzesList(null);
    // getFoldersList(false);
    // getVideosList();
    getSubjects();

    // getClassroomList();
    if (location.state?.classCreate === 1) {
      handleClassCreate();
    }
  }, []);

  // const getClassroomList = () => {
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] =
  //     "application/json;charset=utf-8";
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios
  //     .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quizzes", {
  //       method: "POST",
  //     })
  //     .then((res) => {
  //       console.log("classroom list: ", res.data.classes_data);
  //       setClassroomList(res.data.classes_data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const createNewFolder = (folderDetail) => {
  //   // alert(subject.subject_name);

  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios
  //     .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_folder", {
  //       method: "POST",
  //       folder_name: folderDetail.folderName,
  //       description: folderDetail.description,
  //     })

  //     .then((response) => {
  //       getFoldersList(true);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       alert("unable to add new topic");
  //     });
  // };

  // const onChangeFolderName = (e) => {
  //   setFolderDetail({ ...folderDetail, [e.target.name]: e.target.value });
  // };
  // const onChangeFolderDescription = (e) => {
  //   setFolderDetail({ ...folderDetail, [e.target.name]: e.target.value });
  // };
  const getQuizzesList = async (folder) => {
    // Reset quizzes initially
    setQuizzes([]);

    // Prepare the request body
    const body = {
      folder_id: folder,
      class_id: null,
      subject_id: null,
    };

    try {
      // Call the API to set the quiz data asynchronously
      await getQuizzes(body, setQuizData);
    } catch (error) {
      console.error("Error fetching quiz data:", error);
    }
  };
  const getSharedClassroomList = async () => {
    try {
      // Call the API to set the quiz data asynchronously
      await getSharedClassroom(setClassroomList);
    } catch (error) {
      console.error("Error fetching quiz data:", error);
    }
  };
  // React to changes in quizData after it has been set
  useEffect(() => {
    setQuizDataLoading(quizData?.loading);
    getSharedClassroomList();
    console.log("quiz data", quizData?.data);
    if (quizData?.data) {
      const {
        classes_data = [], // Default to empty array if undefined
        is_class = false,
        is_retrieval = false,
        is_task = false,
        quizzes_data = "[]", // Default to empty JSON array string if undefined
      } = quizData.data;

      // Safely update the state with the retrieved data
      setLatestClassroom(classes_data.length > 0 ? classes_data[0] : null); // Set null if no classroom data
      setClassStatus(is_class);
      setRetievalStatus(is_retrieval);
      setTaskStatus(is_task);
      console.log("quiz data", JSON.parse(quizzes_data));
      setQuizzes(JSON.parse(quizzes_data)); // Parse quizzes_data only if valid
      setClassroomList(classroomList);
      // setClassroomList(classes_data);
    } else {
      console.error("No quiz data available");
    }
  }, [quizData]); // Dependency array ensures this runs when quizData changes

  const editedQuizName = (quiz_id, editedValue) => {
    console.log("quizzes are: ", quizzes);
    let index = quizzes.findIndex((quiz) => quiz.quiz_id === quiz_id);
    let newQuizData = quizzes;
    newQuizData[index].quiz_title = editedValue.toString();
    setQuizzes(newQuizData);
  };
  // const getFoldersList = (isNewFolderCreated) => {
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios
  //     .request({
  //       method: "POST",
  //       url: process.env.REACT_APP_REST_API_BASE_URL + "/get_folders_list",

  //       headers: {
  //         "Content-Type": "application/json; charset=utf-8",
  //         authorization: token,
  //       },
  //     })
  //     .then((res) => {
  //       setFoldersList(res.data);

  //       if (isNewFolderCreated) {
  //         if (res.data.length > 0) {
  //           setSelectedOption(2);
  //         }
  //         if (res.data.length === 1) {
  //           onFolderClick(res.data[res.data.length - 1]);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleClick = () => {
    navigate("/create-quiz", {
      state: {
        folder_id: null,
      },
    });
  };
  // const handleCloseWithCancel = () => {
  //   setCreateDialogStatus(false);
  // };

  // const handleCloseWithYes = () => {
  //   if (folderDetail.folderName === null || folderDetail.folderName === "") {
  //     alert("Folder name cannot be empty");
  //     return;
  //   }

  //   setCreateDialogStatus(false);
  //   createNewFolder(folderDetail);
  // };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    let quizIds = [];
    let stickyEditedQuiz = null;
    for (let index = 0; index < quizzes.length; index++) {
      const element = quizzes[index];
      if (element.quiz_id.toString() === updatedList[0]) {
        setStickyEditQuiz(element);
      }
      quizIds.push(element.quiz_id.toString());
    }
    setStickyEditIndex(quizIds.indexOf(updatedList[0]));
    setChecked(updatedList);
  };
  const handleCancel = () => {
    var quizzesList = document.getElementsByName("quizzes");
    for (let index = 0; index < quizzesList.length; index++) {
      quizzesList[index].checked = false;
    }
    setChecked([]);
  };

  const handleCopyMultipleQuizzes = async () => {
    // Set toaster value initially
    setToasterValue(2);

    // Create the quiz_id_list from checked items
    const quiz_id_list = checked.map((item) => parseInt(item));
    return;
    // Prepare the request body
    let body = {
      quiz_id_list: quiz_id_list,
      quiz_name: null,
      quiz_status: null,
    };

    try {
      // Await the result of the API call
      await getQuizDataForCopy(body);

      // Check if response is valid and proceed

      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("OCCopyQuiz");

      // Refresh quiz list and show the toaster message
      await getQuizzesList(null);
      setToasterValue(2);
      setShow(true);
      setQuizCount(checked.length);

      // Reset or cancel the process
      handleCancel();
    } catch (error) {
      console.error("Error copying quizzes:", error);
    }
  };

  const handleDeleteMultipleQuiz = async () => {
    let body = { quiz_id_list: checked };
    try {
      // Await the result of the API call
      await getStudentDataInQuiz(body, setNewData);

      // Check if response is valid and proceed

      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("OCCopyQuiz");

      // Refresh quiz list and show the toaster message
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("OCDeleteQuiz");
      if (newData.data.msg === 0) {
        setDeleteQuizList(checked);
        setOpenDeleteConfirmationDialogue(true);
        setIsActiveQuiz(false);
      } else {
        setIsActiveQuiz(true);
        setDeleteQuizList(checked);
        setOpenDeleteConfirmationDialogue(true);
      }
    } catch (error) {
      console.error("Error copying quizzes:", error);
    }
  };

  const handleCloseWithCancelDelete = () => {
    setOpenDeleteConfirmationDialogue(false);
  };
  const handleOpenDeleteDialogue = () => {
    setOpenDeleteConfirmationDialogue(true);
  };

  const handleSetDeleteQuiz = (quiz_list) => {
    setDeleteQuizList(quiz_list);
  };

  const handleCloseDeleteWithYes = async () => {
    console.log("DELETING ARRAY : ", deleteQuizList);
    let body = {
      quiz_id_list: deleteQuizList,
      quiz_name: null,
      quiz_status: null,
    };
    try {
      // Await the result of the API call
      await removeQuiz(body);

      // Check if response is valid and proceed

      setOpenDeleteConfirmationDialogue(false);
      getQuizzesList(null);
      handleCancel();
      setShow(true);
      setToasterValue(4);
    } catch (error) {
      console.error("Error copying quizzes:", error);
    }
  };

  const handleSetActive = (isActiveQuiz) => {
    setIsActiveQuiz(isActiveQuiz);
  };
  const handleClassCreate = () => {
    state.handleClickOpenCreateClassroomDialogue(true);
  };
  const handleClickExperience = () => {
    amplitude.getInstance().logEvent("TEBtnClkd");
    localStorage.setItem("experience", "Teacher");
    const newTab = window.open(`/experience`, "_blank");
    // newTab.state = "Teacher";
    // navigate("/experience", {
    //   state: {
    //     experience: "Teacher",
    //   },
    // });
  };
  const handleClickRetrieval = () => {
    amplitude.getInstance().logEvent("TLRBtnClkd");
    window.open("https://teepee.ai/blog/what-is-spaced-retrieval-and-why-its-important/", "_blank");
  };
  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top" />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#49454F",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      borderRadius: "8px",
      opacity: "0.8",
      backgroundColor: "#FFF",
      padding: "12px 16px",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  // const getVideosList = () => {
  //   axios
  //     .request({
  //       method: "GET",
  //       url: process.env.REACT_APP_REST_API_BASE_URL + "/get_videos",
  //       headers: {
  //         "Content-Type": "application/json; charset=utf-8",
  //       },
  //     })
  //     .then((res) => {
  //       console.log(JSON.parse(res.data.data));
  //       setVideoList(JSON.parse(res.data.data));
  //     });
  // };
  const handleCreateClass = () => {
    state.handleClickOpenCreateClassroomDialogue(true);
  };

  const handleNavigateToClassroom = (classroom, type) => {
    state.setDashboardTabsState(type);
    localStorage.setItem("teacher_table_state", 1);
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("ViewClass");

    navigate("/teacher-classroom-home?id=" + classroom.classroom_id);
  };

  const getSubjects = async () => {
    let body = {
      current_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      // Await the API call to set the subject data
      await getStudentSubscriptionTabData(body, setSubjectData);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Use useEffect to react to changes in subjectData after it's updated
  useEffect(() => {
    if (subjectData?.data?.trial_data) {
      const trialSubject = subjectData.data.trial_data.filter((trialSubject) => trialSubject.subject_name !== undefined);

      // Update state with the filtered trial subjects and handle the data
      handleExpiredAndActiveSubjectData(trialSubject);
      setSubjectDataLoading(subjectData?.loading);
    }
  }, [subjectData]); // Dependency array watches subjectData

  const handleExpiredAndActiveSubjectData = (subjectData) => {
    setExpiredSubject(subjectData.filter((subject) => handleSubscriptionType(subject) === 2 || handleSubscriptionType(subject) === 4));
    setMySubjects(subjectData.filter((subject) => handleSubscriptionType(subject) === 1 || handleSubscriptionType(subject) === 3));
  };

  const handleSubscriptionType = (subject) => {
    if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
      subject["text"] = `Trial Ends in ${subject.days_left} Days`;
      subject["sub_heading"] = "Upgrade to keep your progress and enjoy unlimited quizzes and student access.";
      subject["subscription_card_type"] = 1;
      subject["icon"] = "⚠️";
      subject["html_content"] = (
        <div className="flex justify-around items-center mt-4">
          <button className="flex items-center text-[#6750A4FF] font-medium" onClick={() => handleClickOpenUpgrade(subject)}>
            <span style={{ color: "#FFCC00", marginRight: "5px", fontSize: "18px" }}>⚡</span> Upgrade Now
          </button>
          <button className="bg-[#6750A4FF] text-white px-[24px] py-[10px] rounded font-medium" onClick={() => handleViewCourse(subject)}>
            View Course
          </button>
        </div>
      );
      return 1;
    } else if (subject.account_status === 2) {
      subject["text"] = "Trial Expired";
      subject["sub_heading"] = "Upgrade now to access this subject and all features.";
      subject["subscription_card_type"] = 2;
      subject["icon"] = "⛔";
      subject["html_content"] = (
        <div className="flex justify-around items-center mt-4">
          <button className="flex items-center text-[#6750A4FF] font-medium" onClick={() => handleViewCourse(subject)}>
            <span style={{ color: "#FFCC00", marginRight: "5px", fontSize: "18px" }}></span> View Course
          </button>
          <button className="bg-[#6750A4FF] text-white px-[24px] py-[10px] rounded font-medium" onClick={() => handleClickOpenUpgrade(subject)}>
            ⚡ Upgrade Now
          </button>
        </div>
      );
      return 2;
    } else if (
      subject.account_status === 3 ||
      (subject.account_status === 5 && subject.subscription_days_left && subject.subscription_days_left > 0)
    ) {
      subject["text"] = `Active Subscription`;
      subject["sub_heading"] = `Until ${convertDate(subject.subscription_end_date)}`;
      subject["subscription_card_type"] = 3;
      subject["icon"] = "✅";
      subject["html_content"] = (
        <div className="flex justify-around items-center mt-4">
          <button className="flex items-center text-[#6750A4FF] font-medium" onClick={() => handleClickOpenUpgrade(subject)}>
            <span style={{ color: "#FFCC00", marginRight: "5px", fontSize: "18px" }}></span> 👤 Buy More Licences
          </button>
          <button className="bg-[#6750A4FF] text-white px-[24px] py-[10px] rounded font-medium" onClick={() => handleViewCourse(subject)}>
            View Course
          </button>
        </div>
      );
      return 3;
    } else if (subject.account_status === 4 || (subject.account_status === 5 && subject.subscription_days_left <= 0)) {
      subject["text"] = "Subscription Expired";
      subject["sub_heading"] = `On ${convertDate(subject.subscription_end_date)}`;
      subject["subscription_card_type"] = 4;
      subject["icon"] = "⚠️";
      subject["html_content"] = (
        <div className="flex justify-around items-center mt-4">
          <button className="flex items-center text-[#6750A4FF] font-medium" onClick={() => handleViewCourse(subject)}>
            <span style={{ color: "#FFCC00", marginRight: "5px", fontSize: "18px" }}></span> View Course
          </button>
          <button className="bg-[#6750A4FF] text-white px-[24px] py-[10px] rounded font-medium" onClick={() => handleClickOpenUpgrade(subject)}>
            Renew Subscription
          </button>
        </div>
      );
      return 4;
    } else if (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) {
      subject["text"] = "Trial Expired";
      subject["sub_heading"] = "Upgrade now to access this subject and all features.";
      subject["subscription_card_type"] = 2;
      subject["icon"] = "⛔";
      subject["html_content"] = (
        <div className="flex justify-around items-center mt-4">
          <button className="flex items-center text-[#6750A4FF] font-medium" onClick={() => handleViewCourse(subject)}>
            <span style={{ color: "#FFCC00", marginRight: "5px", fontSize: "18px" }}></span> View Course
          </button>
          <button className="bg-[#6750A4FF] text-white px-[24px] py-[10px] rounded font-medium" onClick={() => handleClickOpenUpgrade(subject)}>
            ⚡ Upgrade Now
          </button>
        </div>
      );
      return 2;
    } else if (subject.account_status === 5 && subject.days_left > 0) {
      subject["text"] = `Active Subscription`;
      subject["sub_heading"] = `Until ${convertDate(subject.subscription_end_date)}`;
      subject["subscription_card_type"] = 3;
      subject["icon"] = "✅";
      subject["html_content"] = (
        <div className="flex justify-around items-center mt-4">
          <button className="flex items-center text-[#6750A4FF] font-medium" onClick={() => handleClickOpenUpgrade(subject)}>
            <span style={{ color: "#FFCC00", marginRight: "5px", fontSize: "18px" }}></span> 👤 Buy More Licences
          </button>
          <button className="bg-[#6750A4FF] text-white px-[24px] py-[10px] rounded font-medium" onClick={() => handleViewCourse(subject)}>
            View Course
          </button>
        </div>
      );
      return 3;
    }
  };
  const handleClickOpenUpgrade = (subject) => {
    console.log("upgrade");
    handleOpen(subject);
  };
  const handleViewCourse = (subject) => {
    console.log("view Course");
    sessionStorage.setItem("is_parent", true);
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("ViewSubjectClasses");
    navigate(`/teacher-subject-home?id=${subject.subject_id}`);
  };

  const convertDate = (dateString) => {
    // Parse the input date string into a Date object
    const date = new Date(dateString);

    // Get the day of the month
    const day = date.getDate();

    // Determine the ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 4-20
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const suffix = getOrdinalSuffix(day);
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Format the date string with the ordinal suffix
    return `${day}${suffix} ${month} ${year}`;
  };

  const createOrRetrieveCustomer = async (email) => {
    let body = {
      email: email,
    };

    return new Promise((resolve, reject) => {
      try {
        // Call the API and set the customer state
        getCreateOrRetrieveCustomer(body, (customerData) => {
          // This callback will be triggered when the state (customerData) is updated
          if (customerData?.data?.customer_id) {
            setCustomer(customerData); // Optionally set the customer state
            resolve(customerData.data.customer_id); // Resolve the promise with customer_id
          } else {
            reject("Customer ID not found in the response");
          }
        });
      } catch (error) {
        console.error("Error creating or retrieving customer:", error);
        reject(error); // Reject the promise in case of an error
      }
    });
  };

  const createCustomerPortalSession = async (customerId, returnUrl) => {
    let body = {
      customer_id: customerId,
      return_url: returnUrl,
    };

    return new Promise((resolve, reject) => {
      try {
        // Call the API and set the portal state
        getCreateCustomerPortalSession(body, (portalData) => {
          // This callback is triggered when the portal data is updated
          if (portalData?.data?.url) {
            setPortal(portalData); // Optionally set the portal state
            resolve(portalData.data.url); // Resolve the promise with the URL
          } else {
            reject("Portal URL not found in the response");
          }
        });
      } catch (error) {
        console.error("Error creating customer portal session:", error);
        reject(error); // Reject the promise in case of an error
      }
    });
  };

  const handleOpenManageSubscription = async () => {
    const email = localStorage.getItem("email_address");
    const customerId = await createOrRetrieveCustomer(email);
    let cancel_url = process.env.REACT_APP_CANCEL_URL;
    const returnUrl = `${cancel_url}/subscription`; // Replace with the actual return URL
    const portalUrl = await createCustomerPortalSession(customerId, returnUrl);
    if (portalUrl) {
      window.location.href = portalUrl; // Redirect to the Stripe Customer Portal
    } else {
      console.error("Failed to create customer portal session");
    }
  };
  const handleOpenManageStudents = (subjects) => {
    console.log("SUBJECT: ", subjects);

    subjects["html_content"] = "";
    navigate("/manage-subject", {
      state: {
        selectedSubject: subjects,
      },
    });
  };

  return (
    <>
      <UpgradePlan subjectName={subjectName} subject={subject} open={open} handleClose={handleClose} />
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">
              {toasterValue === 1 || toasterValue === 2 ? "Quiz Copied" : toasterValue === 3 ? "Quiz Renamed!" : "Quiz Deleted!"}
            </strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            {toasterValue === 1 || toasterValue === 2
              ? quizCount === 1
                ? `${quizCount} Task copied successfully!`
                : `${quizCount} Tasks copied successfully!`
              : toasterValue === 3
              ? "Task name updated successfully!"
              : "Task deleted successfully!"}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ConfirmationDialogue
        title={isActiveQuiz ? "Delete Active Task" : "Delete Task"}
        text={
          isActiveQuiz
            ? "This task contains student attempts. Are you sure you want to permanently delete?"
            : "Once deleted, this task will no longer be accessible. Are you sure you want to proceed?"
        }
        open={openDeleteConfirmationDialogue}
        handleCloseWithConfirm={handleCloseDeleteWithYes}
        handleCloseWithCancel={handleCloseWithCancelDelete}
      />
      {/* <Dialog
        className="overflow-y-hidden"
        open={createDialogStatus}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Folder Details</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <div className="mt-[2px]">
            <label className="create_quiz_label">Folder Name</label>
            <input
              type="text"
              required={true}
              value={folderDetail.folderName}
              onChange={onChangeFolderName}
              name="folderName"
              style={{ height: "45px" }}
              className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full px-4 text-black leading-tight  "
              id="folderName"
              placeholder="Enter folder name"
            />
          </div>
          <div>
            <label className="normal_label mt-[13px] ">{"Aditional Detail(optional)"}</label>
            <input
              type="text"
              value={folderDetail.description}
              onChange={onChangeFolderDescription}
              name="description"
              className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full px-4 text-black leading-tight  "
              id="folderDescription"
              placeholder="Enter folder description"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>Cancel</Button>
          <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
      <div>
        <JoinClassCodeDialogue
          openJoinClassroomDialogue={openJoinClassroomDialogue}
          handleClickCloseJoinClassroomDialogue={handleClickCloseJoinClassroomDialogue}
          setOpenJoinClassroomDialogue={setOpenJoinClassroomDialogue}
        />
      </div>
      <div>
        <Sidebar item_id={"quiz"} />
      </div>
      {(subjectDataLoading || quizDataLoading) && (
        <div className=" sm:ml-0 md:ml-[316px] mb-[40px]   overflow-hidden h-screen">
          <div className="w-[90%] mb-[1.813rem] md:ml-[60px]  sm:ml-[10px] flex items-center h-[80vh] justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className=" sm:ml-0 md:ml-[316px] mb-[40px] overflow-x-hidden" style={{ display: subjectDataLoading || quizDataLoading ? "none" : "" }}>
        <StickyFooterDashboard
          handleCopyMultipleQuizzes={handleCopyMultipleQuizzes}
          handleDeleteMultipleQuiz={handleDeleteMultipleQuiz}
          // stickyEditQuiz={props.stickyEditQuiz}
          // editQuizName={editQuizName}
          // stickyEditIndex={props.stickyEditIndex}
          checked={checked}
          handleCancel={handleCancel}
        />
        <div className="flex">
          {/* <div className="quiz-info-light md:items-center w-[60%]"> */}
          {/* <div className="home sm:ml-[20px] md:ml-[60px]"> */}
          {/* <div>
                <label className="dashboard-label">{selectedFolder === null ? "Get Started" : selectedFolder.folder_name}</label>
                <div className="teepee-divider"></div>
              </div> */}
          {/* </div> */}
          {/* </div> */}

          {/* <div className="teepee-label justify-end w-[40%] ">
            <button
              style={{ fontSize: "14px" }}
              className="create-folder-button"
              onClick={() => {
                setCreateDialogStatus(true);
              }}
            >
              Create Folder
            </button>
          </div> */}
        </div>

        <div className="w-[90%] mb-[1.813rem] md:ml-[60px] mt-[4.188rem] sm:ml-[10px]" style={{ fontFamily: "Roboto" }}>
          <div className="">
            <div className="text-[1.5rem] font-bold	 !mb-[1.625rem]">Welcome to Teepee.ai!</div>
            {/* <p className="!mb-[1.438rem] text-[1.25rem] font-regular">With Teepee you can:</p> */}
            <ul className="list-disc list-inside mb-[3.363rem]">
              <li className="mb-[1rem] md:text-[1.042vw] sm:text-[4.8vw] font-regular">
                Create and assign <b>MCQ and exam-style </b> tasks with instant AI-driven marking and feedback.
              </li>
              <li className="md:text-[1.042vw] sm:text-[4.8vw] font-regular">
                Set up <b>Automated & Personalised Spaced Retrieval Practice</b> to ensure students retain what they learn.
              </li>
            </ul>
            <div className="space-y-8">
              <div>
                <div style={{ display: "ruby" }}>
                  <img className={`flex mt-[2px] mb-2 ${!classStatus && "hidden"} mr-2`} style={{ objectFit: "none" }} src={CheckCircle} />
                  <div className="text-[1.0rem] font-bold mb-[0.75rem] tracking-[0.009rem]">1. Create Your Class & Invite Students</div>
                </div>
                {classStatus ? (
                  <div className="mb-[0.75rem] ml-5  tracking-[0.009rem] leading-6">
                    <i>Done! </i> Your class is set up, and students have successfully joined. <br></br>
                    <a
                      href="https://teepee.ai/blog/how-to-create-a-classroom-on-teepee-ai-a-step-by-step-guide/"
                      target="_blank"
                      className="text-[#49454F] text-[12px]  underline"
                    >
                      [Learn more]
                    </a>
                  </div>
                ) : (
                  <p className="mb-[0.75rem] ml-5  tracking-[0.009rem] leading-6">
                    Begin by setting up your class and sharing the unique class code with your students so they can join. <br></br>
                    <a
                      href="https://teepee.ai/blog/how-to-create-a-classroom-on-teepee-ai-a-step-by-step-guide/"
                      target="_blank"
                      className="text-[#49454F]  text-[12px]  underline"
                    >
                      [Learn more]
                    </a>
                  </p>
                )}

                <button onClick={handleCreateClass} className="ml-5 bg-[#6750A4]  text-white text-[14px] font-roboto py-[10px] px-[39px] rounded">
                  Create Your Class
                </button>
              </div>
              <div>
                <div style={{ display: "ruby" }}>
                  <img style={{ objectFit: "none" }} className={`flex mt-[2px] mb-2 ${!retrievalStatus && "hidden"} mr-2`} src={CheckCircle} />
                  <div className="text-[1.0rem] font-bold mb-[0.75rem] tracking-[0.009rem]">2. Set Up Retrieval Practice</div>
                </div>
                {retrievalStatus ? (
                  <p className="mb-[0.75rem] ml-5  tracking-[0.009rem] leading-6">
                    <i>Done! </i> Personalised spaced retrieval sessions are scheduled to reinforce learning and ensure <br /> students retain what
                    they’ve learned.
                    <a
                      href="https://teepee.ai/blog/what-is-spaced-retrieval-and-why-its-important/"
                      target="_blank"
                      className="text-[#49454F] text-[12px]  underline"
                    >
                      [Learn more]
                    </a>
                  </p>
                ) : (
                  <p className="mb-[0.75rem] ml-5  tracking-[0.009rem] leading-6">
                    Once your class is ready and students have joined, set up personalised spaced retrieval sessions to<br></br> reinforce learning.{" "}
                    <a
                      href="https://teepee.ai/blog/what-is-spaced-retrieval-and-why-its-important/"
                      target="_blank"
                      className="text-[#49454F]  text-[12px]  underline"
                    >
                      [Learn more]
                    </a>
                  </p>
                )}
                <button
                  onClick={() => (classStatus ? handleNavigateToClassroom(latestClassroom, 3) : handleCreateClass())}
                  className="ml-5 my-quizzes-card-view-quiz-button border-1 py-2 px-4 rounded font-medium"
                >
                  Set Up Retrieval Practice
                </button>
              </div>
              <div>
                <div style={{ display: "ruby" }}>
                  <img className={`flex mt-[2px] mb-2 ${!taskStatus && "hidden"} mr-2`} src={CheckCircle} />

                  <div className="text-[1.0rem] font-bold mb-[0.75rem] tracking-[0.009rem]">3. Generate Tasks</div>
                </div>
                {taskStatus ? (
                  <p className="mb-[0.75rem] ml-5  tracking-[0.009rem] leading-6">
                    <i>Done! </i> You’ve set up tasks like homework & quizzes, with AI providing instant marking and feedback.
                    <br />
                    <a
                      href="https://teepee.ai/blog/how-to-create-and-share-tasks-on-teepee-ai-a-teachers-guide/"
                      target="_blank"
                      className="text-[#49454F]  text-[12px]  underline"
                    >
                      [Learn more]
                    </a>
                  </p>
                ) : (
                  <p className="mb-[0.75rem] ml-5  tracking-[0.009rem] leading-6">
                    You can generate tasks like homework, mini-mocks, quizzes, etc., here, and AI will provide instant marking and
                    <br></br> and feedback to students.{" "}
                    <a
                      href="https://teepee.ai/blog/how-to-create-and-share-tasks-on-teepee-ai-a-teachers-guide/"
                      target="_blank"
                      className="text-[#49454F]  text-[12px]  underline"
                    >
                      [Learn more]
                    </a>
                  </p>
                )}

                <button
                  onClick={() => (classStatus ? handleNavigateToClassroom(latestClassroom, 1) : handleCreateClass())}
                  className="ml-5 mb-[56px] my-quizzes-card-view-quiz-button border-1 py-2 px-4 rounded"
                >
                  Generate Tasks
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Subscribed or Trialed Subjects */}
        {mySubject.length > 0 && (
          <div className="sm:mb-[15%] md:mb-[50px]">
            <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
              <div>
                <div className="flex">
                  <label className={"dashboard-label cursor-pointer"}>My Subjects</label>
                </div>
                <div className="teepee-divider"></div>
              </div>
            </div>
            <div className="my-quiz-div sm:ml-[20px] md:ml-[60px] mb-[20px]">
              <DashboardSubjectCard
                subjectsData={mySubject}
                handleOpenManageSubscription={handleOpenManageSubscription}
                handleOpenManageStudents={handleOpenManageStudents}
                setSelectedSubject={setSelectedSubject}
                selectedSubject={selectedSubject}
              />
            </div>
          </div>
        )}
        {/* Expired Trial or Expired Subscription Subjects */}
        {expiredSubject.length > 0 && (
          <div className="sm:mb-[15%] md:mb-[50px]">
            <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
              <div>
                <div className="flex">
                  <label className={"dashboard-label cursor-pointer"}>Expired Subjects</label>
                </div>
                <div className="teepee-divider"></div>
              </div>
            </div>
            <div className="my-quiz-div sm:ml-[20px] md:ml-[60px] mb-[20px]">
              <DashboardSubjectCard
                subjectsData={expiredSubject}
                handleOpenManageSubscription={handleOpenManageSubscription}
                handleOpenManageStudents={handleOpenManageStudents}
                setSelectedSubject={setSelectedSubject}
                selectedSubject={selectedSubject}
              />
            </div>
          </div>
        )}

        {/* {selectedFolder === null ? ( */}
        <>
          {/* <div className="flex w-[95%] justify-between"> */}
          {/* <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
                <div>
                  <div className="flex">
                    <label
                      className={
                        selectedOption === 1
                          ? "dashboard-label cursor-pointer"
                          : "disabled-label cursor-pointer"
                      }
                      onClick={() => {
                        setSelectedOption(1);
                      }}
                    >
                      How To
                    </label>
                  </div>

                  {selectedOption === 1 && (
                    <div className="teepee-divider"></div>
                  )}
                </div>
              </div> */}
          {/* <button
                className="view-all"
                onClick={() => navigate("/tutorials")}
              >
                View all
              </button> */}
          {/* </div> */}
          {/* <div className="sm:my-quiz-div sm:ml-[20px]  mb-4">
              <DashboardVideosCard videoList={videoList} />
            </div> */}
          <div style={{ display: classroomList?.data?.data.length === 0 && "none" }}>
            <div className="home sm:ml[25px] md:ml-[60px] ml-[25px] sm:mt-[15%] md:mt-[50px]">
              <div>
                <div className="flex">
                  <label
                    className={selectedOption === 1 ? "dashboard-label cursor-pointer" : "disabled-label cursor-pointer"}
                    onClick={() => {
                      setSelectedOption(1);
                    }}
                  >
                    My Shared Classrooms
                  </label>
                  <ClickAwayListener onClickAway={handleCloseQuizTip}>
                    <div className="flex">
                      <HtmlTooltip
                        title="Display of all shared classrooms"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        enterTouchDelay={0}
                        leaveTouchDelay={10}
                        open={openQuizTooltip}
                        onOpen={handleOpenQuizTip}
                        onClose={handleCloseQuizTip}
                      >
                        <button className="ml-[5px] mt-[2px]" onClick={handleOpenQuizTip}>
                          <img src={Info}></img>
                        </button>
                      </HtmlTooltip>
                    </div>
                  </ClickAwayListener>
                </div>

                {selectedOption === 1 && <div className="teepee-divider"></div>}
              </div>
            </div>
            <DashboardSharedClassCard
              classroomData={classroomList?.data?.data}
              handleClick={handleClick}
              setClassroomList={setClassroomList}
              setOpenJoinClassroomDialogue={setOpenJoinClassroomDialogue}
            />
          </div>
          <div className="w-[95%] md:ml-[60px] sm:ml-[10px]  mt-[24px]">
            <div className="mb-[2.438rem]" style={{ width: "fit-content" }}>
              <label className="dashboard-label">Experience & Explore</label>
              <div className="teepee-divider"></div>
            </div>
            <GetStartedCards
              classicon={classicon}
              handleClickExperience={handleClickExperience}
              handleClick={handleClick}
              handleClassCreate={handleClassCreate}
              classroomData={classroomList}
              handleClickRetrieval={handleClickRetrieval}
            />
          </div>
          <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]" style={{ display: quizzes.length === 0 ? "none" : "" }}>
            <div>
              <div className="flex">
                <label className="dashboard-label cursor-pointer">My Quizzes</label>
                <ClickAwayListener onClickAway={handleCloseQuizTip}>
                  <div className="flex">
                    <HtmlTooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={10}
                      title="All quizzes created outside classroom"
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleCloseQuizTip}
                      onOpen={handleOpenQuizTip}
                      open={openQuizTooltip}
                    >
                      <button className="ml-[5px] mt-[2px]" onClick={handleOpenQuizTip}>
                        <img src={Info}></img>
                      </button>
                    </HtmlTooltip>
                  </div>
                </ClickAwayListener>
              </div>
              <div className="teepee-divider"></div>
            </div>

            {/* <div className="pl-10">
     <label
       className={
         selectedOption === 2
           ? "dashboard-label cursor-pointer"
           : "disabled-label cursor-pointer"
       }
       onClick={() => {
         setSelectedOption(2);
       }}
     >
       My Folders
     </label>
     {selectedOption === 2 && <div className="teepee-divider"></div>}
   </div> */}
          </div>
        </>
        {/* ) : ( */}
        {/* <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]"> */}
        {/* <div className="">
              <label
                className={
                  selectedOption === 2
                    ? "dashboard-label cursor-pointer"
                    : "disabled-label cursor-pointer"
                }
                onClick={() => {
                  setSelectedOption(2);
                  setSelectedFolder(null);
                  getQuizzesList(null);
                  getFoldersList(false);
                }}
              >
                My Folders
              </label>
              {selectedOption === 2 && <div className="teepee-divider"></div>}
            </div> */}
        {/* </div> */}
        {/* )} */}
        <div className="my-quiz-div sm:ml-[20px] md:ml-[60px] mb-[20px]">
          <DashboardQuizCard
            handleClick={handleClick}
            handleSetActive={handleSetActive}
            toasterValue={toasterValue}
            handleSetDeleteQuiz={handleSetDeleteQuiz}
            editedQuizName={editedQuizName}
            stickyEditQuiz={stickyEditQuiz}
            stickyEditIndex={stickyEditIndex}
            handleCancel={handleCancel}
            getQuizzesList={getQuizzesList}
            checked={checked}
            handleCheck={handleCheck}
            quizData={quizzes}
            folders_list={foldersList}
            refreshData={refreshData}
            handleCloseWithCancelDelete={handleCloseWithCancelDelete}
            handleOpenDeleteDialogue={handleOpenDeleteDialogue}
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
