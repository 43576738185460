import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import EdiText from "react-editext";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useUpdateNotification from "../../../NotificationApiCall/UpdateNotification";
import useDeleteNotification from "../../../NotificationApiCall/DeleteNotification";

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  padding: theme.spacing(1),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#F0F0F0",
}));

const NotificationAccordion = ({ variants, setVariants, variant }) => {
  const [expandedId, setExpandedId] = React.useState(null);

  const toggleAccordion = (id) => {
    setExpandedId((prevExpandedId) => (prevExpandedId === id ? null : id));
  };
  const updateNotification = useUpdateNotification();
  const deleteNotification = useDeleteNotification();
  const handleSave = (id, val) => {
    const updatedVariants = variants.map((v) => (v.quiz_notification_id === id ? { ...v, title: val } : v));
    setVariants(updatedVariants);
    updateNotification(id, val);
  };

  const handleDelete = (id, e) => {
    e.stopPropagation();
    const filteredVariants = variants.filter((v) => v.quiz_notification_id !== id);
    setVariants(filteredVariants);
    deleteNotification(id);
  };

  return (
    <div className="overflow-auto">
      <Accordion
        key={variant.quiz_notification_id}
        expanded={expandedId === variant.quiz_notification_id}
        onChange={() => toggleAccordion(variant.quiz_notification_id)}
      >
        <AccordionSummary
          aria-controls={`panel-${variant.quiz_notification_id}-content`}
          id={`panel-${variant.quiz_notification_id}-header`}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className="w-full h-[0px] flex justify-between items-center">
            Variant: {variant.quiz_notification_id + 1}
            <Button onClick={(e) => handleDelete(variant.quiz_notification_id, e)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full" style={{ backgroundColor: "#FFFFFF", padding: "12px" }}>
            <EdiText
              type="text"
              onSave={(val) => handleSave(variant.quiz_notification_id, val)}
              value={variant.notification_text}
              className="w-full"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default NotificationAccordion;
