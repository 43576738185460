import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import useGetPricing from "./PricingApiCall/GetPricing";
import useCreatePricing from "./PricingApiCall/CreatePricing";
import useUpdatePricing from "./PricingApiCall/UpdatePricing";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
// import { useForm } from "react-hook-form";
const AdminPricing = () => {
  const isSubmitting = useRef(false);
  const [costPerStudent, setCostPerStudent] = useState("");
  const [monthlyCostStart, setMonthlyCostStart] = useState("");
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const getPricing = useGetPricing();
  const createPricing = useCreatePricing();
  const updatePricing = useUpdatePricing();
  const handleSubmit = () => {
    isSubmitting.current = true;
    // Simulate async operation like an API call
    if (id === null) {
      createPricing(costPerStudent, monthlyCostStart, isSubmitting, setShow);
      getPricing(setCostPerStudent, setMonthlyCostStart, setId);
    } else {
      updatePricing(id, costPerStudent, monthlyCostStart, isSubmitting, setShow);
    }
    // console.log({ costPerStudent, monthlyCostStart }); // Just for demonstration; replace with actual logic
  };
  useEffect(() => {
    getPricing(setCostPerStudent, setMonthlyCostStart, setId);
  }, []);
  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">{id === null ? "Created!" : "Updated!"}</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>pricing has been {id === null ? "Created!" : "Updated!"}.</Toast.Body>
        </Toast>
      </ToastContainer>
      <Sidebar item_id="pricing" />

      <div className="ml-[316px] mt-8">
        <div className=" sm:ml[25px] md:ml-[60px]">
          <div className="home">
            <div>
              <label className="dashboard-label">Pricing </label>
              <div className="teepee-divider"></div>
            </div>
          </div>
          <form className="mt-4 w-[30vw]">
            <div className="flex items-center mb-4">
              <label className="mr-2">Cost per student, per subject, per year: £</label>
              <input
                type="text"
                value={costPerStudent}
                onChange={(e) => setCostPerStudent(e.target.value)}
                className="teepee-input"
                style={{ width: "60px", height: "48px" }} // Set width to 20px
                placeholder="£"
              />
            </div>
            <div className="flex items-center mb-4">
              <label className="mr-2">Monthly Cost feature starts from:</label>
              <input
                type="text"
                value={monthlyCostStart}
                onChange={(e) => setMonthlyCostStart(e.target.value)}
                className="teepee-input"
                style={{ width: "60px", height: "48px" }} // Set width to 20px
                placeholder="#"
              />
              <span className="ml-2">Students</span>
            </div>
            <button
              onClick={handleSubmit}
              type="button" // Prevent form submission
              disabled={monthlyCostStart?.length === 0 || costPerStudent?.length === 0}
              className="teepee-button hover:bg-black disabled:bg-gray-300 disabled:cursor-not-allowed"
              style={{ width: "200px" }} // Adjust the width as needed
            >
              {isSubmitting.current === true && (
                <div className="spinner-border spinner-border-m float-left" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {id === null ? "Create" : "Update"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminPricing;
