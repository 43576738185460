import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_DELETE_NOTIFICATION_END_POINT } from "../../Constants/ApiConstant";

const useDeleteNotification = () => {
  const deleteNotification = (id) => {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_DELETE_NOTIFICATION_END_POINT,
        {
          notification_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return deleteNotification;
};

export default useDeleteNotification;
