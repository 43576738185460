import axios from "axios";
import { REACT_APP_REST_API_BASE_URL, REACT_APP_GET_NOTIFICATION_END_POINT } from "../../Constants/ApiConstant";

const useGetNotification = () => {
  const getNotification = (callback) => {
    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    // Using GET request instead of POST
    axios
      .get(`${REACT_APP_REST_API_BASE_URL}${REACT_APP_GET_NOTIFICATION_END_POINT}`, config)
      .then((response) => {
        callback(response.data.notification_data);
      })
      .catch((error) => {
        console.error("Failed to fetch notifications:", error);
        // Optionally handle errors in state or pass error up to calling component
      });
  };

  return getNotification;
};

export default useGetNotification;
