import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Button from "@mui/material/Button";
import NotificationAccordion from "./Admin/Retrival/Content/NotificationAccordian";
import useCreateNotification from "./NotificationApiCall/CreateNotification";
import useGetNotification from "./NotificationApiCall/GetNotification";
const CustomNotification = () => {
  const [variants, setVariants] = useState([]);
  const [variantCount, setVariantCount] = useState(0);

  const getNotification = useGetNotification();
  const createNotification = useCreateNotification();
  const addVariant = async (e) => {
    e.stopPropagation();
    try {
      await createNotification("add content here");

      await getNotification(setVariants);
    } catch (error) {
      console.error("Error in notification operations:", error);
    }
  };

  useEffect(() => {
    getNotification(setVariants);
  }, [variants]);
  return (
    <>
      <Sidebar item_id="custom_notification" />
      <div className="ml-[336px] p-4">
        {variants.length > 0 ? (
          variants.map((variant, index) => (
            <NotificationAccordion key={variant.quiz_notification_id} index={index} variant={variant} setVariants={setVariants} variants={variants} />
          ))
        ) : (
          <p></p>
        )}
        <Button
          type="button"
          variant="contained"
          onClick={(e) => {
            addVariant(e);
          }}
          className="delete-button"
          style={{ backgroundColor: "white", color: "#1976d2", padding: "6px 8px", border: "none", marginTop: "12px" }}
        >
          Add Variant
        </Button>
      </div>
    </>
  );
};

export default CustomNotification;
